import { Box } from '@mui/material';

export const Footer = () => {
    return (
        <Box sx = {{ display:'flex', width:'100%', justifyContent:'center', pb:'1rem', color:'rgba(0,0,0,.4)', fontSize:'1rem'}}>
            <Box>
                Copyright © 2022 zimu.live&nbsp;&nbsp;&nbsp;<a href="https://afdian.net/a/bili-rec"target="_blank">爱发电</a>
            </Box>
        </Box>
    );
}