export default {
    url: {
        //api: 'https://api.zimu.live:8443',
        api: 'https://api.zimu.bili.studio:44443',
        author: '//space.bilibili.com',
        clip: '//www.bilibili.com/video'
    },
    static: {
	    // url: 'https://static.zimu.live:8443'
        url: 'https://static.bili.studio:8444'
    }
};
